import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { acceptHireRequest, dashboardHome, sendPaymentLink } from "../../urls";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Upload,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  formatRequestStatus,
  formatSpace,
  getRequestStatusColor,
} from "../../functions/Formatters";
import {
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  SPACE_ID_FLEX_WORKSPACE,
  SPACE_ID_STUDIO,
} from "../../constants/SpacesConstants";
import axios from "axios";
import { getFirebaseIDToken } from "../../functions/Firebase";
import dayjs from "dayjs";
import { FIRESTORE_HIRE_REQUESTS_TABLE } from "../../constants/FirebaseConstants";
import { useFirestore } from "react-redux-firebase";
import {
  HIRE_REQUEST_STATUS_ACCEPTED,
  HIRE_REQUEST_STATUS_DETAILS_SUBMITTED,
  HIRE_REQUEST_STATUS_OPEN,
  HIRE_REQUEST_STATUS_REJECTED,
  HIRE_REQUEST_STATUS_SIGNED,
  HIRE_REQUEST_STATUSSES,
  PAYMENT_METHOD_INVOICE,
} from "../../constants/HireRequestConstants";
import { dummyRequest } from "../../functions/Upload";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { v4 } from "uuid";
import { cloneDeep, sortBy } from "lodash";
import utils from "../../utils";

const Admin = () => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const requests = useSelector(
    (state) => state.firestoreReducer.ordered.hireRequests,
  );
  const navigate = useNavigate();
  const [acceptRequestForm] = Form.useForm();
  const [uploadFileForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [acceptUserModalOpen, setAcceptUserModalOpen] = useState(false);
  const [selectedSpaceType, setSelectedSpaceType] = useState(null);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [requestDrawerOpen, setRequestDrawerOpen] = useState(false);
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (profile.isLoaded) {
      if (!profile.isEmpty && profile.role !== "admin") navigate(dashboardHome);
      setIsLoading(false);
    }
  }, [profile]);

  const onAcceptHireRequest = () => {
    // Studio or flex workspace
    acceptRequestForm.validateFields().then((values) => {
      setSubmitLoading(true);
      getFirebaseIDToken().then((token) => {
        const config = {
          headers: { Authorization: token },
        };
        values.id = currentRequest.id;
        axios
          .post(acceptHireRequest, values, config)
          .then((res) => {
            message.success(t("notifications.request_accepted"));
            setSubmitLoading(false);
            closeAcceptRequestModal();
          })
          .catch((err) => {
            setSubmitLoading(false);
            console.log(err);
            message.error(t("errors.generic"));
          });
      });
    });
  };

  const onRejectHireRequest = (request) => {
    firestore
      .collection(FIRESTORE_HIRE_REQUESTS_TABLE)
      .doc(request.id)
      .update({
        status: HIRE_REQUEST_STATUS_REJECTED,
        updatedAt: dayjs().unix(),
      })
      .then(() => {
        message.success(t("notifications.request_rejected"));
      })
      .catch(() => message.error(t("errors.generic")));
  };

  const onSaveRentAgreement = () => {
    uploadFileForm.validateFields().then((values) => {
      setSubmitLoading(true);
      const storage = getStorage();
      const fileName = v4() + "-" + uploadedFile.name;
      const base64PartOfFile = uploadedFile.fileBase64.substr(
        uploadedFile.fileBase64.indexOf(",") + 1,
      );
      const fileRef = ref(storage, `huurcontracten/${fileName}`);
      uploadString(fileRef, base64PartOfFile, "base64")
        .then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            // Check if currentRequest is defined
            if (!currentRequest) {
              console.error("currentRequest is undefined");
              setSubmitLoading(false);
              message.error(t("notifications.rent_agreement_not_uploaded"));
              return;
            }

            let logs = cloneDeep(currentRequest.logs) || {};
            logs.uploadedRentAgreement = dayjs().unix();

            firestore
              .collection(FIRESTORE_HIRE_REQUESTS_TABLE)
              .doc(currentRequest.id)
              .update({
                status: HIRE_REQUEST_STATUS_SIGNED,
                rentAgreement: url,
                squareMeters: values.squareMeters || null,
                updatedAt: dayjs().unix(),
                logs: logs,
              })
              .then(() => {
                getFirebaseIDToken().then((token) => {
                  const config = {
                    headers: { Authorization: token },
                  };
                  const object = {
                    id: currentRequest.id,
                    chosenSpace: currentRequest.chosenSpace,
                    email: currentRequest.email,
                    firstName: currentRequest.firstName,
                    lastName: currentRequest.lastName,
                    phone: currentRequest.phone,
                    squareMeters: values.squareMeters || null,
                    rentAgreement: url,
                    companyDetails: currentRequest.companyDetails,
                  };
                  axios
                    .post(sendPaymentLink, object, config)
                    .then((res) => {
                      message.success(
                        t("notifications.rent_agreement_uploaded"),
                      );
                      setSubmitLoading(false);
                      setUploadDocumentModalOpen(false);
                      uploadFileForm.resetFields();
                    })
                    .catch((err) => {
                      console.log(err);
                      message.error(err);
                    });
                });
              })
              .catch((err) => {
                console.log(err);
                message.error(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
        });
    });
  };

  const onPressUploadDocument = (request) => {
    setCurrentRequest(request);
    setUploadDocumentModalOpen(true);
    setSelectedSpaceType(request.chosenSpace);
    uploadFileForm.setFieldsValue({ spaceType: request.chosenSpace });
  };

  const openRequestDetails = (request) => {
    setCurrentRequest(request);
    setRequestDrawerOpen(true);
  };

  const closeRequestDrawer = () => {
    setCurrentRequest(null);
    setRequestDrawerOpen(false);
  };

  const onPressAcceptRequest = (request) => {
    if (
      request.chosenSpace === SPACE_ID_FLEX_WORKSPACE ||
      request.chosenSpace === SPACE_ID_STUDIO
    ) {
      setSelectedSpaceType(request.chosenSpace);
      setCurrentRequest(request);
      acceptRequestForm.setFieldsValue({
        firstName: request.firstName,
        lastName: request.lastName,
        email: request.email,
        phone: request.phone,
        spaceType: request.chosenSpace,
      });
      setAcceptUserModalOpen(true);
    }
  };

  const closeAcceptRequestModal = () => {
    setAcceptUserModalOpen(false);
    acceptRequestForm.resetFields();
    setCurrentRequest(null);
  };

  const requestStatusMenu = (request) => (
    <Menu>
      {HIRE_REQUEST_STATUSSES.filter((item) => item !== request.status).map(
        (item, key) => {
          return (
            <Menu.Item key={key}>
              <Popconfirm
                title={t("change_request_status_to", {
                  status: formatRequestStatus(item).toLowerCase(),
                })}
                onConfirm={() => {
                  firestore
                    .collection(FIRESTORE_HIRE_REQUESTS_TABLE)
                    .doc(request.id)
                    .update({ status: item, updatedAt: dayjs().unix() })
                    .then(() => message.success(t("status_changed_success")))
                    .catch(() => message.error(t("status_changed_failure")));
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <a onClick={(e) => e.preventDefault()}>
                  {formatRequestStatus(item)}
                </a>
              </Popconfirm>
            </Menu.Item>
          );
        },
      )}
    </Menu>
  );

  return (
    <DashboardLayout style={{ padding: 24 }}>
      <Card title={t("hire_requests")} loading={isLoading}>
        <Table
          scroll={{ x: true }}
          columns={[
            {
              title: t("date"),
              dataIndex: "createdAt",
              render: (data) => (
                <span style={{ whiteSpace: "nowrap" }}>
                  {dayjs.unix(data).format("DD-MM-YYYY")}
                </span>
              ),
              sorter: (a, b) => utils.antdTableSorter(a, b, "createdAt"),
              defaultSortOrder: "descend",
            },
            {
              title: t("name"),
              render: (_, data) => (
                <span style={{ whiteSpace: "nowrap" }}>
                  {data.firstName + " " + data.lastName}
                </span>
              ),
            },
            {
              title: t("email"),
              dataIndex: "email",
            },
            {
              title: t("phone"),
              dataIndex: "phone",
            },
            {
              title: t("occupation"),
              dataIndex: "occupation",
              render: (data) => (
                <p
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    lineClamp: 2,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    marginBottom: 0,
                  }}
                >
                  {data}
                </p>
              ),
            },
            {
              title: t("space_type"),
              dataIndex: "chosenSpace",
              render: (data) => <span>{formatSpace(data)}</span>,
            },
            {
              title: t("status"),
              dataIndex: "status",
              render: (_, request) => {
                if (request.status !== HIRE_REQUEST_STATUS_OPEN)
                  return (
                    <Dropdown
                      overlay={requestStatusMenu(request)}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Tag color={getRequestStatusColor(request.status)}>
                          {formatRequestStatus(request.status)}
                        </Tag>
                      </a>
                    </Dropdown>
                  );
                else
                  return (
                    <Tag color={getRequestStatusColor(request.status)}>
                      {formatRequestStatus(request.status)}
                    </Tag>
                  );
              },
            },
            {
              title: t("action"),
              key: "action",
              render: (_, record) => (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          openRequestDetails(record);
                        }}
                        key={"details"}
                      >
                        <div className={"d-flex align-items-center"}>
                          <EyeOutlined />
                          <span style={{ marginLeft: 4 }}>{t("details")}</span>
                        </div>
                      </Menu.Item>
                      {record.status === HIRE_REQUEST_STATUS_OPEN && (
                        <Menu.Item
                          onClick={() => {
                            onPressAcceptRequest(record);
                          }}
                          key={"accept"}
                        >
                          <div className={"d-flex align-items-center"}>
                            <CheckOutlined />
                            <span style={{ marginLeft: 4 }}>{t("accept")}</span>
                          </div>
                        </Menu.Item>
                      )}
                      {record.status === HIRE_REQUEST_STATUS_OPEN && (
                        <Menu.Item
                          onClick={() => {
                            onRejectHireRequest(record);
                          }}
                          key={"reject"}
                        >
                          <div className={"d-flex align-items-center"}>
                            <CloseOutlined />
                            <span style={{ marginLeft: 4 }}>{t("reject")}</span>
                          </div>
                        </Menu.Item>
                      )}
                      {(record.status === HIRE_REQUEST_STATUS_ACCEPTED ||
                        record.status ===
                          HIRE_REQUEST_STATUS_DETAILS_SUBMITTED) && (
                        <Menu.Item
                          onClick={() => {
                            onPressUploadDocument(record);
                          }}
                          key={"upload_rent_agreement"}
                        >
                          <div className={"d-flex align-items-center"}>
                            <UploadOutlined />
                            <span style={{ marginLeft: 4 }}>
                              {t("upload_rent_agreement")}
                            </span>
                          </div>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <EllipsisOutlined
                    style={{ transform: "rotate(90deg)", cursor: "pointer" }}
                  />
                </Dropdown>
              ),
            },
          ]}
          dataSource={sortBy(requests, "createdAt")}
        />
      </Card>
      <Modal
        open={acceptUserModalOpen}
        title={t("accept_request")}
        okText={t("save")}
        cancelText={t("close")}
        onOk={onAcceptHireRequest}
        onCancel={() => setAcceptUserModalOpen(false)}
        width={750}
        confirmLoading={submitLoading}
      >
        <Form form={acceptRequestForm} layout="vertical" className={"mt-3"}>
          <Row gutter={[16, 16]}>
            <Col lg={12}>
              <Form.Item
                name="firstName"
                label={t("first_name")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_first_name"),
                  },
                ]}
              >
                <Input placeholder={t("first_name")} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                name="lastName"
                label={t("last_name")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_last_name"),
                  },
                ]}
              >
                <Input placeholder={t("last_name")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col lg={12}>
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_email"),
                  },
                  {
                    type: "email",
                    message: t("form.enter_valid_email"),
                  },
                ]}
              >
                <Input placeholder={t("email")} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                name="phone"
                label={t("phone_number")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_phone"),
                  },
                ]}
              >
                <Input placeholder={t("phone_number")} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        open={uploadDocumentModalOpen}
        title={t("upload_rent_agreement")}
        okText={t("save")}
        cancelText={t("close")}
        onOk={onSaveRentAgreement}
        onCancel={() => {
          setUploadDocumentModalOpen(false);
          setCurrentRequest(null);
        }}
        width={750}
        confirmLoading={submitLoading}
      >
        <Form form={uploadFileForm} layout="vertical" className={"mt-3"}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                name="file"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!!uploadedFile) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("form.upload_file")));
                    },
                  }),
                ]}
              >
                <Upload
                  name={"file"}
                  customRequest={dummyRequest}
                  headers={{
                    authorization: "authorization-text",
                  }}
                  maxCount={1}
                  beforeUpload={(file) => {
                    // Only accepted jpg, png and pdf files
                    const acceptedFileType = ["application/pdf"];
                    const isAcceptedFileType = acceptedFileType.includes(
                      file.type,
                    );
                    if (!isAcceptedFileType) {
                      message.error(t("form.file_type_error"));
                    }

                    const reader = new FileReader();

                    reader.onload = (e) => {
                      setUploadedFile({
                        name: file.name,
                        fileBase64: e.target.result,
                      });
                    };
                    reader.readAsDataURL(file);

                    // Prevent upload
                    return isAcceptedFileType || Upload.LIST_IGNORE;
                  }}
                  onChange={(info) => {
                    if (info.file.status === "removed") setUploadedFile(null);

                    if (info.file.status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}
                >
                  <Button icon={<UploadOutlined />} style={{ marginTop: 0 }}>
                    {t("upload_file")}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 16]}>
            <Col lg={12}>
              <Form.Item
                name="spaceType"
                label={t("space_type")}
                rules={[
                  {
                    required: true,
                    message: t("form.pick_value"),
                  },
                ]}
              >
                <Select
                  onChange={(value) => setSelectedSpaceType(value)}
                  disabled
                >
                  <Select.Option value={SPACE_ID_STUDIO}>
                    {t("studio_space")}
                  </Select.Option>
                  <Select.Option value={SPACE_ID_FLEX_WORKSPACE}>
                    {t("flex_space")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {selectedSpaceType === SPACE_ID_STUDIO && (
              <Col lg={12}>
                <Form.Item
                  name="squareMeters"
                  label={t("square_meters")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={t("square_meters")}
                    className={"w-100"}
                    min={1}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      <Drawer
        title={t("request_details")}
        placement="right"
        onClose={closeRequestDrawer}
        open={requestDrawerOpen}
        width={800}
      >
        {!!currentRequest && (
          <>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label={t("status")}>
                <Tag color={getRequestStatusColor(currentRequest.status)}>
                  {formatRequestStatus(currentRequest.status)}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label={t("date")}>
                {dayjs.unix(currentRequest.createdAt).format("DD-MM-YYYY")}
              </Descriptions.Item>
              <Descriptions.Item label={t("name")}>
                {currentRequest.firstName + " " + currentRequest.lastName}
              </Descriptions.Item>
              <Descriptions.Item label={t("email")}>
                {currentRequest.email}
              </Descriptions.Item>
              <Descriptions.Item label={t("phone")}>
                {currentRequest.phone}
              </Descriptions.Item>
              <Descriptions.Item label={t("space_type")}>
                {formatSpace(currentRequest.chosenSpace)}
              </Descriptions.Item>
              <Descriptions.Item label={t("explanation_request")}>
                {currentRequest.occupation}
              </Descriptions.Item>
              {!!currentRequest.squareMeters && (
                <Descriptions.Item label={t("square_meters")}>
                  {t(currentRequest.squareMeters)}
                </Descriptions.Item>
              )}
              {!!currentRequest.rentAgreement && (
                <Descriptions.Item label={t("rent_agreement")}>
                  <a href={currentRequest.rentAgreement} target="_blank">
                    {t("download")}
                  </a>
                </Descriptions.Item>
              )}
            </Descriptions>
            {!!currentRequest.companyDetails && (
              <Descriptions
                bordered
                size="small"
                column={1}
                className={"mt-3"}
                title={t("company_details")}
              >
                <Descriptions.Item label={t("company_name")}>
                  {currentRequest.companyDetails.companyName}
                </Descriptions.Item>
                <Descriptions.Item label={t("company_tax_number")}>
                  {currentRequest.companyDetails.companyTaxNumber}
                </Descriptions.Item>
                <Descriptions.Item label={t("chamber_of_commerce_number")}>
                  {currentRequest.companyDetails.chamberOfCommerceNumber}
                </Descriptions.Item>
                <Descriptions.Item label={t("name")}>
                  {currentRequest.companyDetails.firstName +
                    " " +
                    currentRequest.companyDetails.lastName}
                </Descriptions.Item>
                <Descriptions.Item label={t("email")}>
                  {currentRequest.companyDetails.email}
                </Descriptions.Item>
                <Descriptions.Item label={t("phone")}>
                  {currentRequest.companyDetails.phone}
                </Descriptions.Item>
                <Descriptions.Item label={t("date_of_birth")}>
                  {currentRequest.companyDetails.dateOfBirth}
                </Descriptions.Item>
                <Descriptions.Item label={t("address")}>
                  {currentRequest.companyDetails.street +
                    " " +
                    currentRequest.companyDetails.houseNumber +
                    (!!currentRequest.companyDetails.addition
                      ? currentRequest.companyDetails.addition + ", "
                      : ", ") +
                    currentRequest.companyDetails.zipCode +
                    " " +
                    currentRequest.companyDetails.city}
                </Descriptions.Item>
                <Descriptions.Item label={t("payment_method")}>
                  {t(currentRequest.companyDetails.paymentMethod)}
                </Descriptions.Item>
              </Descriptions>
            )}
            {!!currentRequest.logs && (
              <Descriptions
                bordered
                size="small"
                column={1}
                className={"mt-3"}
                title={
                  <div>
                    {t("request_logs")} (
                    <a
                      href={
                        "https://firebasestorage.googleapis.com/v0/b/oostwest-c3713.appspot.com/o/documents%2FOnboarding%20proces%20bij%20OostWest%201.0.pdf?alt=media&token=be2a5ab8-0098-4719-8390-e74c6220dd40"
                      }
                      target="_blank"
                    >
                      {t("customer_onboarding_process")}
                    </a>
                    )
                  </div>
                }
              >
                <Descriptions.Item label={t("logs.accepted_mail_sent")}>
                  {currentRequest.logs["acceptedMailSent"]
                    ? dayjs
                        .unix(currentRequest.logs["acceptedMailSent"])
                        .format("DD-MM-YYYY HH:mm")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label={t("logs.added_details")}>
                  {currentRequest.logs["addedDetails"]
                    ? dayjs
                        .unix(currentRequest.logs["addedDetails"])
                        .format("DD-MM-YYYY HH:mm")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label={t("logs.created_customer")}>
                  {currentRequest.logs["createdCustomer"]
                    ? dayjs
                        .unix(currentRequest.logs["createdCustomer"])
                        .format("DD-MM-YYYY HH:mm")
                    : "-"}
                </Descriptions.Item>
                {!!currentRequest.companyDetails &&
                  currentRequest.companyDetails.paymentMethod ===
                    PAYMENT_METHOD_INVOICE && (
                    <>
                      <Descriptions.Item label={t("logs.subscription_created")}>
                        {currentRequest.logs["subscriptionCreated"]
                          ? dayjs
                              .unix(currentRequest.logs["subscriptionCreated"])
                              .format("DD-MM-YYYY HH:mm")
                          : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("logs.invoice_email_sent")}>
                        {currentRequest.logs["invoiceEmailSent"]
                          ? dayjs
                              .unix(currentRequest.logs["invoiceEmailSent"])
                              .format("DD-MM-YYYY HH:mm")
                          : "-"}
                      </Descriptions.Item>
                    </>
                  )}
                <Descriptions.Item label={t("logs.checkout_session_created")}>
                  {currentRequest.logs["checkoutSessionCreated"]
                    ? dayjs
                        .unix(currentRequest.logs["checkoutSessionCreated"])
                        .format("DD-MM-YYYY HH:mm")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label={t("logs.setup_payment_email_sent")}>
                  {currentRequest.logs["setupPaymentEmailSent"]
                    ? dayjs
                        .unix(currentRequest.logs["setupPaymentEmailSent"])
                        .format("DD-MM-YYYY HH:mm")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label={t("logs.payment_confirmation_sent")}>
                  {currentRequest.logs["paymentConfirmationSent"]
                    ? dayjs
                        .unix(currentRequest.logs["paymentConfirmationSent"])
                        .format("DD-MM-YYYY HH:mm")
                    : "-"}
                </Descriptions.Item>
              </Descriptions>
            )}
          </>
        )}
      </Drawer>
    </DashboardLayout>
  );
};

export default Admin;
